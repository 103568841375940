var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, useToast } from '@chakra-ui/react';
import { ContainerWithHeader } from '@frontend/design-system/components/DataDisplay/ContainerWithHeader';
import { LoadingWithControl } from '@frontend/design-system/components/LoadingWithControl/LoadingWithControl';
import { Tabs } from '@frontend/design-system/components/Tabs/Tabs';
import { variants } from '@frontend/design-system/theme/theme';
import { SettingsPageMode, } from '@frontend/domain/models/Settings/SettingsInterfaces';
import { useEffect, useState } from 'react';
import { InputError } from '../../../objects/Exceptions';
import { PrioritizationAlgorithmCriteriaList } from './PrioritizationAlgorithmCriteriaList';
export const PrioritizationAlgorithmsContainer = ({ canEdit, orgPrioritizationAlgorithms, priorityCriteria, upsertPrioritizationAlgorithm, refreshPriorityCriteria, }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [prioritizationAlgorithms, setPrioritizationAlgorithms] = useState([]);
    const [criteriaOptions, setCriteriaOptions] = useState([]);
    const [pageMode, setPageMode] = useState(SettingsPageMode.VIEW);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const toast = useToast();
    useEffect(() => {
        let algorithmTemp = [];
        if (orgPrioritizationAlgorithms &&
            orgPrioritizationAlgorithms.length > 0) {
            algorithmTemp = orgPrioritizationAlgorithms.map((algorithm) => {
                const criteriaTemp = algorithm.criteria.map((criteria) => {
                    return {
                        identity: criteria.identity,
                        name: criteria.name,
                        weight: criteria.weight,
                    };
                });
                return {
                    identity: algorithm.identity,
                    criteria: criteriaTemp,
                };
            });
            setPrioritizationAlgorithms(algorithmTemp);
        }
    }, [orgPrioritizationAlgorithms]);
    useEffect(() => {
        const convertedOptions = priorityCriteria.map((criteria) => {
            return { label: criteria.name, value: criteria.name };
        });
        setCriteriaOptions(convertedOptions);
    }, [priorityCriteria]);
    const hasNewCriteria = (data) => {
        return data.some((updatedAlgorithm, index) => {
            const originalAlgorithm = orgPrioritizationAlgorithms[index];
            return updatedAlgorithm.criteria.some((updatedCriteria) => {
                const foundOriginalCriterion = originalAlgorithm.criteria.find((original) => original.identity === updatedCriteria.identity);
                return !foundOriginalCriterion;
            });
        });
    };
    const handlePrioritizationSave = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsLoading(true);
            const data = orgPrioritizationAlgorithms.map((algorithm, index) => ({
                identity: algorithm.identity,
                criteria: prioritizationAlgorithms[index].criteria,
            }));
            const isNewCriteria = hasNewCriteria(data);
            const response = yield upsertPrioritizationAlgorithm(data);
            if (response) {
                if (isNewCriteria) {
                    toast({
                        title: 'New criteria has been created',
                        description: 'Go to myProjects to rescore any projects that are "Needs Action"',
                        status: 'warning',
                        duration: null,
                        isClosable: true,
                    });
                }
                refreshPriorityCriteria();
                setCanSave(false);
            }
            setIsLoading(false);
        }
        catch (error) {
            setIsLoading(false);
            if (error instanceof InputError) {
                toast({
                    title: 'Save Failed',
                    description: error.message,
                    status: 'error',
                    isClosable: true,
                });
            }
        }
    });
    const onRemove = (criteriaToRemove, tabIndex) => {
        setCanSave(true);
        const algorithmToUpdate = prioritizationAlgorithms[tabIndex];
        const updatedCriteriaList = algorithmToUpdate.criteria.filter((criteria) => {
            return criteriaToRemove.name !== criteria.name;
        });
        const updatedAlgorithm = Object.assign(Object.assign({}, algorithmToUpdate), { criteria: updatedCriteriaList });
        setPrioritizationAlgorithms((prevPrioritizationAlgorithms) => {
            const prioritizationAlgorithms = [...prevPrioritizationAlgorithms];
            prioritizationAlgorithms[tabIndex] = updatedAlgorithm;
            return prioritizationAlgorithms;
        });
    };
    const updateAlgorithmCriteria = (prioritizationAlgorithms, tabIndex, updatedData, originalData) => {
        const algorithmToUpdate = prioritizationAlgorithms[tabIndex];
        const updatedCriteriaList = algorithmToUpdate.criteria.map((criteria) => {
            if (criteria.identity && updatedData.identity) {
                if (criteria.identity === updatedData.identity) {
                    return updatedData;
                }
            }
            if (criteria.name === originalData.name) {
                return updatedData;
            }
            return criteria;
        });
        const updatedAlgorithm = Object.assign(Object.assign({}, algorithmToUpdate), { criteria: updatedCriteriaList });
        return prioritizationAlgorithms.map((alg) => {
            if (alg.identity === algorithmToUpdate.identity) {
                return updatedAlgorithm;
            }
            else if (alg.identity !== algorithmToUpdate.identity &&
                alg.criteria.some((criteria) => criteria.name === (originalData === null || originalData === void 0 ? void 0 : originalData.name))) {
                const updatedCriteriaList = alg.criteria.map((criteria) => criteria.name === (originalData === null || originalData === void 0 ? void 0 : originalData.name)
                    ? Object.assign(Object.assign({}, criteria), { name: updatedData.name }) : criteria);
                return Object.assign(Object.assign({}, alg), { criteria: updatedCriteriaList });
            }
            else {
                return alg;
            }
        });
    };
    const addAlgorithmCriteria = (prioritizationAlgorithms, tabIndex, updatedData) => {
        const algorithmToAdd = prioritizationAlgorithms[tabIndex];
        const newCriteriaList = [...algorithmToAdd.criteria, updatedData];
        const updatedAlgorithm = Object.assign(Object.assign({}, algorithmToAdd), { criteria: newCriteriaList });
        return prioritizationAlgorithms.map((alg) => alg.identity === algorithmToAdd.identity ? updatedAlgorithm : alg);
    };
    const acceptPrioritizations = (updatedData, tabIndex, originalData) => {
        if (originalData) {
            setPrioritizationAlgorithms((prev) => updateAlgorithmCriteria(prev, tabIndex, updatedData, originalData));
            const updatedCriteriaOptionsList = criteriaOptions.map((criteria) => {
                return criteria.value === originalData.name
                    ? {
                        label: updatedData.name,
                        value: updatedData.name,
                    }
                    : criteria;
            });
            setCriteriaOptions(updatedCriteriaOptionsList);
        }
        else {
            setPrioritizationAlgorithms((prev) => addAlgorithmCriteria(prev, tabIndex, updatedData));
            const isOptionExists = criteriaOptions.find((option) => option.value === updatedData.name);
            !isOptionExists &&
                setCriteriaOptions([
                    ...criteriaOptions,
                    { label: updatedData.name, value: updatedData.name },
                ]);
        }
        if (updatedData.name !== '') {
            setCanSave(true);
        }
    };
    const tabData = (prioritizationAlgorithms || []).map((algorithm, algorithmIndex) => {
        return {
            key: algorithmIndex,
            column: `Algorithm ${algorithmIndex + 1}`,
            nestedComponent: (_jsx(PrioritizationAlgorithmCriteriaList, { currentTabIndex: currentTabIndex, prioritizationAlgorithms: prioritizationAlgorithms, algorithmCriteriaList: algorithm.criteria, canEdit: canEdit, pageMode: pageMode, onRemove: onRemove, acceptPrioritizations: acceptPrioritizations, setPageMode: setPageMode, criteriaOptions: criteriaOptions, priorityCriteria: priorityCriteria }, algorithmIndex)),
        };
    });
    const renderPrioritizationSaveButton = () => {
        return (_jsx(Button, { onClick: handlePrioritizationSave, variant: variants.blueTransparentBtn, textTransform: 'none', pl: '20px', pr: '20px', children: "Save" }));
    };
    return (_jsxs(ContainerWithHeader, { header: {
            label: 'Prioritization Algorithm',
            button: canSave && renderPrioritizationSaveButton(),
        }, children: [_jsx(Tabs, { tabs: tabData, onChange: (index) => setCurrentTabIndex(index) }), _jsx(LoadingWithControl, { isLoading: isLoading })] }));
};
